var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-box-center-items-vertical"},[_c('div',{staticClass:"flex-box-right-aligned-horizontal",staticStyle:{"align-items":"flex-end"}},[_c('div',{staticStyle:{"margin-right":"5px"}},[_vm._v("Bitte geben Sie ihre Postleitzahl an")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.$vuetify.theme.themes.light.secondary,"size":"dense","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}])},[_c('span',[_vm._v(" Die Daten werden anonym gespeichert. Es wird nur die PLZ übermittelt, nicht die vollständige Adresse. Es ist anhand der Angaben kein Rückschluss auf Sie als Person möglich. ")])]),_c('input',{staticStyle:{"margin-left":"10px","margin-right":"10px","padding-left":"5px","width":"100px"},style:(_vm.colorOfInputElementsStyle),domProps:{"value":_vm.pollPlz()},on:{"change":_vm.pollPlzChanged}}),_c('v-btn',{attrs:{"disabled":!_vm.isPollValid || _vm.isSubmitted},on:{"click":function($event){return _vm.submitPoll()}}},[_vm._v("absenden")])],1),(!_vm.isPollPlzValid())?_c('span',{staticStyle:{"color":"red","align-self":"end"}},[_vm._v("Postleitzahl ungültig!")]):_vm._e(),(!_vm.isFrequenciesValid())?_c('span',{staticStyle:{"color":"red","align-self":"end"}},[_vm._v("Gesamthäufigkeit der Transportvarianten ungültig!")]):_vm._e(),(!_vm.isTransportsUsedValid())?_c('span',{staticStyle:{"color":"red","align-self":"end"}},[_vm._v("Bitte wählen Sie genau eine Option aus der obenstehenden Liste aus!")]):_vm._e(),_c('span',{style:({
      color:
        _vm.isSubmitted && _vm.submitText
          ? _vm.$vuetify.theme.themes.light.secondary
          : _vm.submitText
          ? 'red'
          : '',
    })},[_vm._v(_vm._s(_vm.submitText))])])}
var staticRenderFns = []

export { render, staticRenderFns }