<template>
  <v-dialog
    v-model="showPrivacy"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="700px"
    style="z-index: 1000;"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot v-bind:on="on" v-bind:attrs="attrs" />
    </template>

    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title>Datenschutz</v-toolbar-title>
        <v-spacer/>
        <v-btn icon @click="showPrivacy = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="mt-6">
        Wir nehmen Ihre Privatsphäre ernst, und meinen das — anders als manch anderer Anbieter — auch so.
        Wir verzichten explizit auf jegliches Tracking. Der Formalität und Transparenz halber heißt das im Detail:
      </v-card-text>

      <v-card-title class="headline">Erhobene Daten</v-card-title>
      <v-card-text>
        Im Rahmen der Verwendung dieses Tools werden verschiedene Daten erfasst.
        Diese sind begrenzt personenbeziehbar:
        <h4>Inhaltsdaten</h4>
        <ul>
          <li>Adresse des Startorts für das Routing</li>
          <li>Start- und End-Zeitpunkte für das Routing</li>
        </ul>
        <h4>Metadaten</h4>
        <ul>
          <li>Zeitpunkt des Seitenaufrufs</li>
          <li>Typ &amp; Version des Browsers</li>
        </ul>
        Wir verzichten explizit auf die Speicherung ihrer IP Adresse.
      </v-card-text>

      <v-card-title class="headline">Zweck der Datenerhebung</v-card-title>
      <v-card-text>
        Die erhobenen Inhaltsdaten werden für die Kernfunktionalität des Angebots benötigt.
        <br/>Eine temporäre Speicherung dient der Qualitätssicherung des Angebots.
      </v-card-text>

      <v-card-title class="headline">Weitergabe der Daten</v-card-title>
      <v-card-text>
        Durch Nutzung dieses Tools werden die angegeben Daten durch Dienste Dritter verarbeitet:
        <ul>
          <li>
            <a href="https://openrouteservice.org" target="_blank">OpenRouteService</a>
            : Geocoding der Adresse in Geokoordinaten
          </li>
          <li>
            <a href="https://www.mapbox.com" target="_blank">MapBox</a>
            : Geocoding der Adresse in Geokoordinaten
          </li>
        </ul>
      </v-card-text>

      <v-card-title class="headline">Speicherung der Daten</v-card-title>
      <v-card-text>
        Es findet keine dauerhafte Speicherung statt.
        Temporäre Logdateien werden im Intervall von 10 Tagen gelöscht.
      </v-card-text>

      <v-card-title class="headline">Ihre Rechte</v-card-title>
      <v-card-text>
        Nach DSGVO haben Sie das Recht auf Löschung und Auskunft der zu Ihrer Person gespeicherten Daten.
        Nutzen Sie dazu bitte die im
        <a :href="impressumUrl" taget="_blank">Impressum</a>
        genannten Kontaktmöglichkeiten.
        Bitte beachten Sie, dass wir Ihnen ohne Mitteilung Ihrer Addresse keinen Log-Eintrag zuordnen können.
        Weiter haben Sie nach DSGVO Art. 77 das Recht auf Beschwerde bei einer Aufsichtsbehörde, bspw. dem
        Datenschutzbeauftragen des Landes.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    name: 'PrivacyDialog',

    props: {
      impressumUrl: String
    },

    data: () => ({
      showPrivacy: false,
    }),
  })
</script>
