/*
 * Cleverroute
 * Cleverroute API
 *
 * OpenAPI spec version: 0.0.2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.46
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The RouteMetrics model module.
 * @module model/RouteMetrics
 * @version 0.0.2
 */
export class RouteMetrics {
  /**
   * Constructs a new <code>RouteMetrics</code>.
   * average metric values of journey there and back
   * @alias module:model/RouteMetrics
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>RouteMetrics</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RouteMetrics} obj Optional instance to populate.
   * @return {module:model/RouteMetrics} The populated <code>RouteMetrics</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RouteMetrics();
      if (data.hasOwnProperty('sport'))
        obj.sport = ApiClient.convertToType(data['sport'], 'Number');
      if (data.hasOwnProperty('co2Fixed'))
        obj.co2Fixed = ApiClient.convertToType(data['co2Fixed'], 'Number');
      if (data.hasOwnProperty('co2Journey'))
        obj.co2Journey = ApiClient.convertToType(data['co2Journey'], 'Number');
      if (data.hasOwnProperty('time'))
        obj.time = ApiClient.convertToType(data['time'], 'Number');
      if (data.hasOwnProperty('costFixed'))
        obj.costFixed = ApiClient.convertToType(data['costFixed'], 'Number');
      if (data.hasOwnProperty('costJourney'))
        obj.costJourney = ApiClient.convertToType(data['costJourney'], 'Number');
      if (data.hasOwnProperty('costJourneyThere'))
        obj.costJourneyThere = ApiClient.convertToType(data['costJourneyThere'], 'Number');
      if (data.hasOwnProperty('costJourneyBack'))
        obj.costJourneyBack = ApiClient.convertToType(data['costJourneyBack'], 'Number');
      if (data.hasOwnProperty('isCostJourneyThereComplete'))
        obj.isCostJourneyThereComplete = ApiClient.convertToType(data['isCostJourneyThereComplete'], 'Boolean');
      if (data.hasOwnProperty('isCostJourneyBackComplete'))
        obj.isCostJourneyBackComplete = ApiClient.convertToType(data['isCostJourneyBackComplete'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {Number} sport
 */
RouteMetrics.prototype.sport = undefined;

/**
 * @member {Number} co2Fixed
 */
RouteMetrics.prototype.co2Fixed = undefined;

/**
 * @member {Number} co2Journey
 */
RouteMetrics.prototype.co2Journey = undefined;

/**
 * @member {Number} time
 */
RouteMetrics.prototype.time = undefined;

/**
 * @member {Number} costFixed
 */
RouteMetrics.prototype.costFixed = undefined;

/**
 * @member {Number} costJourney
 */
RouteMetrics.prototype.costJourney = undefined;

/**
 * @member {Number} costJourneyThere
 */
RouteMetrics.prototype.costJourneyThere = undefined;

/**
 * @member {Number} costJourneyBack
 */
RouteMetrics.prototype.costJourneyBack = undefined;

/**
 * @member {Boolean} isCostJourneyThereComplete
 */
RouteMetrics.prototype.isCostJourneyThereComplete = undefined;

/**
 * @member {Boolean} isCostJourneyBackComplete
 */
RouteMetrics.prototype.isCostJourneyBackComplete = undefined;

