<template>
  <div class="flex-box-center-items-vertical" style="align-items: center">
    <template v-if="!isTransportUsageInPercentage()">
      <div
        style="height: 100%; width: 100%"
        class="flex-box-center-items-horizontal"
      >
        <span>Häufigkeit pro Woche</span>
        <v-tooltip top>
          <template v-slot:activator="{on, attrs}"
            ><v-icon
              :color="$vuetify.theme.themes.light.secondary"
              size="dense"
              dark
              v-bind="attrs"
              v-on="on"
              >mdi-help-circle</v-icon
            ></template
          >
          <span
            >Bitte bewerte auch diese Fahrtvariante durch Auswahl der
            Sterne.</span
          >
        </v-tooltip>
      </div>
      <div
        style="height: 100%; width: 100%"
        class="flex-box-center-items-horizontal"
      >
        <v-btn
          @click="decrementPollFrequency(routeId)"
          style="
            min-width: 20%;
            padding: 0 3px;
            height: 100%;
            align-items: center;
          "
        >
          <v-icon> remove </v-icon>
        </v-btn>
        <input
          type="text"
          outlined
          @change="pollFrequencyChanged(routeId, $event)"
          :value="getPollFreq(routeId)"
          style="height: 100%; min-width: 60%; text-align: center"
          :style="colorOfInputElementsStyle"
        />
        <v-btn
          @click="incrementPollFrequency(routeId)"
          style="
            min-width: 20%;
            padding: 0 3px;
            height: 100%;
            text-align: center;
          "
        >
          <v-icon> add </v-icon>
        </v-btn>
      </div>
    </template>
    <template v-else>
      <div
        style="height: 100%; width: 100%"
        class="flex-box-center-items-horizontal"
      >
        <span>Häufigkeit pro Jahr (in %)</span>
      </div>
      <div
        style="height: 100%; width: 100%"
        class="flex-box-center-items-horizontal"
      >
        <input
          type="text"
          outlined
          @change="pollFrequencyChanged(routeId, $event)"
          :value="getPollFreq(routeId)"
          style="height: 100%; min-width: 60%; text-align: center"
          :style="colorOfInputElementsStyle"
        />
      </div>
      <span v-if="!isPercentageValid(routeId)" style="color: red">Eintrag ungültig!</span>
    </template>

    <span v-if="transportMode == 'car'" style="align-self: center"
      >Personenanzahl im PKW</span
    >

    <div
      v-if="transportMode == 'car'"
      style="height: 100%; width: 100%"
      class="flex-box-center-items-horizontal"
    >
      <v-btn
        @click="decrementPassengers(routeId)"
        style="
          min-width: 20%;
          padding: 0 3px;
          height: 100%;
          align-items: center;
        "
      >
        <v-icon> remove </v-icon>
      </v-btn>
      <input
        type="text"
        outlined
        @change="nPassengersChanged(routeId, $event)"
        :value="getNumberPassengers(routeId)"
        style="height: 100%; min-width: 60%; text-align: center"
        :style="colorOfInputElementsStyle"
      />
      <v-btn
        @click="incrementPassengers(routeId)"
        style="min-width: 20%; padding: 0 3px; height: 100%; text-align: center"
      >
        <v-icon> add </v-icon>
      </v-btn>
      <!-- </div> -->
    </div>
    <PollRating
      :routeId="routeId"
      :config="config"
      :routes="routes"
    ></PollRating>
    <!-- <div class="flex-box-left-aligned-horizontal">
    
    <v-tooltip top>
      <template v-slot:activator="{on, attrs}"
        ><div  v-bind="attrs" v-on="on"
          >
        <v-rating
        v-bind="attrs"
          v-on="on"
      :background-color="$vuetify.theme.themes.light.secondary"
      :color="$vuetify.theme.themes.light.secondary"
      :size="isSmallScreenWidth() ? '15' : '25'"
    >my rating</v-rating></div>
        </template
      >
      <span>Wie findest du diese Fahrtvariante? </span>

    
    </v-tooltip> 
   
  </div> -->
  </div>
</template>

<script>
import {storeToRefs} from "pinia";
import Vue from "vue";
import {usePollStore} from "../../store/poll-store";
import * as utils from "../../utils";
import PollRating from "./PollRating.vue";

export default Vue.extend({
  name: "PollCompanyInputForm",

  components: {
    PollRating,
  },

  props: {
    routeId: {type: Number, default: 0},
    config: {type: Object, default: undefined},
    transportMode: {type: String, default: ""},
    routes: {type: Array, default: () => []},
  },

  data: () => ({}),

  mounted() {},

  methods: {
    isTransportUsageInPercentage() {
      const frequencyType = storeToRefs(usePollStore()).frequencyType.value;
      switch (frequencyType) {
        case "percentage":
          return true;
        case "days":
          return false;
        default:
          return false;
      }
    },
    isPercentageValid(i) {
      return usePollStore().pollFrequency(i) >=0  && usePollStore().pollFrequency(i) <= 100;
    },
    isSmallScreenWidth() {
      return window.innerWidth < 700;
    },
    incrementPollFrequency(i) {
      // this.pollFrequencies[i].freq += 1;
      usePollStore().incrementFrequency(i);
    },
    decrementPollFrequency(i) {
      // if (this.pollFrequencies[i].freq > 0) {
      //   this.pollFrequencies[i].freq -= 1;
      // }
      usePollStore().decrementFrequency(i);
    },
    pollFrequencyChanged(i, event) {
      let val = event.target?.value;
      const num = Number(val);
      usePollStore().setFrequency(num, i);
      // this.pollFrequencies[i].freq = num;
    },
    getPollFreq(i) {
      return usePollStore().pollFrequency(i);
      // if (this.pollFrequencies.length - 1 >= i) {
      //   return this.pollFrequencies[i].freq;
      // } else {
      //   return 0;
      // }
    },
    incrementPassengers(i) {
      usePollStore().incrementNumberPassengers(i);

      // this.numberPassengers[i].freq += 1;
    },
    decrementPassengers(i) {
      // if (this.numberPassengers[i].freq > 0) {
      //   this.numberPassengers[i].freq -= 1;
      // }
      usePollStore().decrementNumberPassengers(i);
    },
    nPassengersChanged(i, event) {
      let val = event.target?.value;
      const num = Number(val);
      usePollStore().setNumberPassengers(num, i);
      // this.numberPassengers[i].freq = num;
    },
    getNumberPassengers(i) {
      return usePollStore().numberPassengersOfTransport(i);
      // if (this.numberPassengers.length - 1 >= i) {
      //   return this.numberPassengers[i].freq;
      // } else {
      //   return 0;
      // }
    },
  },

  computed: {
    colorOfInputElementsStyle() {
      return utils.colorOfInputElementsStyle.bind(this)();
    },
  },

  watch: {
    routes: {
      handler: function (o, n) {
        this.lastUpdated = Date.now();
        // init poll frequencies
        this.routes.forEach((r, i) => {
          if (
            !storeToRefs(usePollStore()).frequencies.value.find(
              (poll) => poll.transportId === r.transport.id
            )
          ) {
            usePollStore().addNumberPassengers({
              freq: 0,
              transportId: r.transport.id,
            });
            usePollStore().addFrequency({freq: 0, transportId: r.transport.id});
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
});
</script>

<style scoped>
.full-width {
  width: 100%;
}
.sort-btns >>> button:not(.v-btn--active),
.sort-btns >>> i,
button {
  color: #333 !important;
}
.background-white {
  background-color: white;
}
.padding-10px {
  padding: 10px;
}
.border-radius-10px {
  border-radius: 10px;
}
.flex-box-centered-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}
.font-size-22px {
  font-size: 22px;
}
.margin-left-15px {
  margin-left: 15px;
}
</style>
