var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({'background-color': 'white', 'padding': '15px', 'border': '2px solid '+ _vm.$vuetify.theme.themes.light.secondary}),attrs:{"elevation":"2"}},[_c('h3',[_vm._v(_vm._s(_vm.title))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}})],1),_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.introductionSteps),function(myText,i){return _c('span',{key:i},[_vm._v(" "+_vm._s(myText)+" "),_c('br')])}),0)],1)],1),((_vm.pollMonthsInOrganization || _vm.pollWorkingDays || _vm.pollHomeofficeDays))?_c('v-divider',{staticStyle:{"margin-top":"10px","margin-bottom":"10px"},attrs:{"orientation":"horizontal"}}):_vm._e(),((_vm.pollMonthsInOrganization || _vm.pollWorkingDays || _vm.pollHomeofficeDays))?_c('v-row',[(_vm.pollMonthsInOrganization !== undefined)?_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('div',{staticClass:"flex-box-center-items-vertical",staticStyle:{"align-items":"center"}},[_c('div',{staticClass:"flex-box-center-items-horizontal"},[_c('div',{staticStyle:{"margin":"5px"}},[_vm._v("Monate in Organisation 2023")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.$vuetify.theme.themes.light.secondary,"size":"dense","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}],null,false,276210192)},[_c('span',[_vm._v("Pass hier falls notwendig die Zahl der Monate an, die du im aktuellen Jahr in der Organisation tätig bist. ")])]),_c('input',{staticStyle:{"padding-left":"5px","width":"40px"},style:(_vm.colorOfInputElementsStyle),domProps:{"value":_vm.pollMonthsInOrganization},on:{"change":function($event){return _vm.pollOverviewDataChanged(
              _vm.PollData.PollMonthsInOrganization,
              $event.target.value
            )}}}),_c('span',{staticClass:"flex-box-center-items-vertical"},[_vm._v(" / 12")])],1),(!_vm.isPollMonthsInOrganizationValid())?_c('span',{staticStyle:{"color":"red"}},[_vm._v("Eintrag ungültig!")]):_vm._e()])]):_vm._e(),(_vm.pollWorkingDays !== undefined)?_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('div',{staticClass:"flex-box-center-items-vertical",staticStyle:{"align-items":"center"}},[_c('div',{staticClass:"flex-box-center-items-horizontal",staticStyle:{"align-items":"center"}},[_c('div',{staticStyle:{"margin":"5px"}},[_vm._v("Arbeitstage (Woche)")]),_c('input',{staticStyle:{"padding-left":"5px","width":"40px"},style:(_vm.colorOfInputElementsStyle),domProps:{"value":_vm.pollWorkingDays},on:{"change":function($event){return _vm.pollOverviewDataChanged(_vm.PollData.PollWorkingDays, $event.target.value)}}})]),(!_vm.isPollWorkingDaysValid())?_c('span',{staticStyle:{"color":"red"}},[_vm._v("Eintrag ungültig!")]):_vm._e()])]):_vm._e(),(_vm.pollHomeofficeDays !== undefined)?_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('div',{staticClass:"flex-box-center-items-vertical",staticStyle:{"align-items":"center"}},[_c('div',{staticClass:"flex-box-center-items-horizontal"},[_c('div',{staticStyle:{"margin":"5px"}},[_vm._v("Homeoffice-Tage (Woche)")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.$vuetify.theme.themes.light.secondary,"size":"dense","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}],null,false,276210192)},[_c('span',[_vm._v("Pass hier falls notwendig die Zahl deiner Homeoffice-Tage in einer durchschnittlichen Arbeitswoche an. ")])]),_c('input',{staticStyle:{"padding-left":"5px","width":"40px"},style:(_vm.colorOfInputElementsStyle),domProps:{"value":_vm.pollHomeofficeDays},on:{"change":function($event){return _vm.pollOverviewDataChanged(_vm.PollData.PollHomeofficeDays, $event.target.value)}}})],1),(!_vm.isPollHomeofficeDaysValid())?_c('span',{staticStyle:{"color":"red"}},[_vm._v("Eintrag ungültig!")]):_vm._e()])]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }