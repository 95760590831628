<template>
  <div v-if="!printView">
    <v-tabs
      v-if="isJourneyBackAvailable()"
      fixed-tabs
      @change="directionChanged($event)"
      :value="directionTab"
    >
      <v-tab>Hinfahrt</v-tab>
      <v-tab>Rückfahrt</v-tab>
    </v-tabs>

    <v-tabs-items :value="directionTab">
      <v-tab-item v-for="(route, i) in routes" :key="i">
        <v-timeline dense align-top>
          <v-list shaped>
            <v-list-item-group v-model="selectedLeg" color="primary">
              <v-list-item>
                <div :style="paddingHeadersWidth()"></div>

                <v-row no-gutters class="full-max-width">
                  <v-col
                    :cols="titleCols"
                    class="d-flex justify-center align-center"
                  >
                  </v-col>

                  <v-col
                    v-if="!isSmallScreenWidth"
                    :cols="compoundCols"
                    class="d-flex justify-center align-center"
                  >
                  </v-col>
                  <v-col
                    v-if="showTrackRow"
                    :cols="trackCols"
                    class="d-flex justify-center align-center"
                  >
                    Gleis
                  </v-col>
                  <v-col
                    v-if="!isSmallScreenWidth"
                    :cols="priceCols"
                    class="d-flex justify-center align-center"
                  >
                    Kosten/<br />CO₂
                  </v-col>
                  <v-col
                    :cols="arrivalCols"
                    class="d-flex justify-center align-center"
                  >
                    Ankunft/<br />Abfahrt
                  </v-col>
                  <v-col
                    :cols="durationCols"
                    class="d-flex justify-center align-center"
                  >
                    Dauer/<br />Strecke
                  </v-col>
                </v-row>
              </v-list-item>
              <RouteLeg
                v-for="(leg, i) in activeRoute.legs || [activeRoute]"
                :minutesCongestion="minutesCongestionStr"
                :minutesParking="minutesParkingStr"
                :key="i"
                :leg="leg"
                :titleCols="titleCols"
                :trackCols="trackCols"
                :priceCols="priceCols"
                :arrivalCols="arrivalCols"
                :durationCols="durationCols"
                :compoundCols="compoundCols"
                :print-view="printView"
                :isSmallScreenWidth="isSmallScreenWidth"
                :showTrackRow="showTrackRow"
                :compoundName="getCompoundName(i)"
                :ticketPrice="getTicketPriceOfLeg(i)"
                :priceHint="getPriceHintOfLeg(i)"
                :co2="getCo2OfLeg(i)"
                :iconPath="getIconName(i)"
                :departurePlatform="getPlatformOfLeg(i, 'departurePlatform')"
                :arrivalPlatform="getPlatformOfLeg(i, 'arrivalPlatform')"
              />
            </v-list-item-group>
          </v-list>
        </v-timeline>
      </v-tab-item>
    </v-tabs-items>
  </div>

  <div v-else>
    <div v-for="(route, i) in routes" :key="i" class="route">
      <h1
        class="text-center font-weight-light"
        v-once
        v-text="i ? 'Rückfahrt' : 'Hinfahrt'"
      />
      <v-timeline dense :align-top="printView">
        <RouteLeg
          v-for="(leg, i) in route.legs || [route]"
          :minutesCongestion="minutesCongestion"
          :minutesParking="minutesParking"
          :key="i"
          :leg="leg"
          :print-view="printView"
        />
      </v-timeline>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { formatNumberToLocaleAnd2Fixed, formatCo2ByValue, considerPriceOfLeg } from "../utils";
import RouteLeg from "./RouteLeg.vue";

export default Vue.extend({
  name: "Route",

  components: {
    RouteLeg,
  },

  props: {
    directionTab: { type: Number, default: 0 },
    updateDirection: { type: Function },
    searchParams: {
      type: Object,
      default: () => ({
        origin: "",
        departure: "", // contains Date instances when filled out
        arrival: "",
        destination: "",
        includeIntermodal: false,
        minutesCongestion: "0",
        minutesParking: "0",
      }),
    },
    routes: { type: Array, default: () => [] },
    printView: Boolean,
  },

  data: () => ({
    innerWidth: window.innerWidth,
    // directionTab: 0,
    selectedLeg: undefined,
    minutesCongestion: "0",
    minutesParking: "0",
  }),

  computed: {
    showTrackRow() {
      let legsWithPlatforms = this.activeRoute.legs.filter(
        (leg, i) =>
          this.getPlatformOfLeg(i, "departurePlatform") !== "" ||
          this.getPlatformOfLeg(i, "arrivalPlatform") !== ""
      );
      return legsWithPlatforms.length > 0;
    },
    durationCols() {
      if (!this.isSmallScreenWidth) {
        if (this.showTrackRow) {
          return 2;
        } else if (!this.showTrackRow) {
          return 3;
        }
      } else {
        if (this.showTrackRow) {
          return 3;
        } else if (!this.showTrackRow) {
          return 4;
        }
      }
      // return !this.isSmallScreenWidth ? 2 : 3;
    },
    arrivalCols() {
      return !this.isSmallScreenWidth ? 2 : 4;
    },
    priceCols() {
      return 2;
    },
    trackCols() {
      return 1;
    },
    titleCols() {
      return 4;
    },
    compoundCols() {
      return 1;
    },
    isSmallScreenWidth() {
      return this.innerWidth < 600;
    },
    activeRoute() {
      if (this.directionTab == 1 && this.routes[this.directionTab]) {
        return this.routes[this.directionTab];
      } else {
        return this.routes[0];
      }
    },
    minutesParkingStr() {
      // Passing to RouteLeg the value always as string even if it is passed as number, as otherwise error is raised
      return String(this.minutesParking);
    },
    minutesCongestionStr() {
      // Passing to RouteLeg the value always as string even if it is passed as number, as otherwise error is raised
      return String(this.minutesCongestion);
    },
  },

  methods: {
    getPriceHintOfLeg(i) {
      const costsUnavailable =  this.activeRoute.legs[i].hasOwnProperty('costCents') &&
        this.activeRoute.legs[i].costCents === 0;
      let priceHintMessages = [];

      if (this.activeRoute.legs[i].hasOwnProperty('sharingStationalCost') &&
      this.activeRoute.legs[i].sharingStationalCost && !costsUnavailable) {
        priceHintMessages.push("Standkosten sind hinzugerechnet");
      }
      if (this.activeRoute.legs[i].hasOwnProperty('sharingActivationCost') &&
      this.activeRoute.legs[i].sharingActivationCost && !costsUnavailable) {
        priceHintMessages.push("Aktivierungsgebühren sind hinzugerechnet");
      }
      if (costsUnavailable) {
        priceHintMessages.push("Ticketpreis nicht vorhanden");
      }
      
      const messagesStr = priceHintMessages.length > 0 ? priceHintMessages.join(". \n") : ""; 
      return messagesStr;
    },
    paddingHeadersWidth() {
      if (!this.isSmallScreenWidth) {
        return { width: "60px" };
      } else {
        if (this.showTrackRow) {
          return { width: "0px" };
        } else if (!this.showTrackRow) {
          return { width: "50px" };
        }
      }
    },
    onResize() {
      this.innerWidth = window.innerWidth;
    },
    getCo2OfLeg(i) {
      return formatCo2ByValue(this.activeRoute.legs[i].co2);
    },
   
    getTicketPriceOfLeg(i) {
      const considerPrice = this.activeRoute.legs[i].hasOwnProperty('costCents');//considerPriceOfLeg(this.activeRoute.legs, i);
      if (considerPrice) {
        const price = this.activeRoute.legs[i].costCents;
        // toLocaleString sets a comma instead of a dot separator
        let res = formatNumberToLocaleAnd2Fixed(Number(price) / 100);
        if (res === 0) {
          return "0";
        }
        return res;
      } else {
        return "";
      }
    },
    getIconName(i) {
      if (this.activeRoute.legs[i].mode !== "foot") {
        if (this.activeRoute.legs[i].providerIcon) {
          return `./provider_logos/${this.activeRoute.legs[i].providerIcon}.png`;
        } else if (this.activeRoute.legs[i].verbundNameShort) {
          return `./compounds_logos/${this.activeRoute.legs[i].verbundNameShort}-logo.png`;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    /* retrieves the compound name like 'vvs' if it is not a walking leg and compound-name is given  */
    getCompoundName(i) {
      if (
        this.activeRoute.legs[i].mode !== "foot" &&
        this.activeRoute.legs[i].verbundNameShort
      ) {
        return this.activeRoute.legs[i].verbundNameShort;
      } else {
        return "";
      }
    },
    getPlatformOfLeg(i, mode) {
      return this.activeRoute.legs[i][mode]
        ? this.activeRoute.legs[i][mode]
        : "";
    },
    isJourneyBackAvailable() {
      return this.routes.length >= 0 && this.routes[1] ? true : false;
    },
    directionChanged(event) {
      this.updateDirection(event);
    },
    emitGeom() {
      // get either journey-there or journey-back, depending on the chosen direction
      const r = this.activeRoute;
      // get the Number of the chosen leg (NOT the id! ID is (‚selectedLeg - 1)
      const leg = this.selectedLeg;

      let geo = r.legs[leg - 1 || 0].geometry;
      if (leg === undefined)
        geo = {
          type: "GeometryCollection",
          geometries: r.legs.map((l) => l.geometry),
        };

      if (geo) {
        this.$emit("geometry-changed", geo);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    directionTab() {
      this.selectedLeg = undefined;
      this.emitGeom();
    },
    selectedLeg() {
      this.emitGeom();
    },
    routes() {
      this.minutesCongestion = this.searchParams.minutesCongestion;
      this.minutesParking = this.searchParams.minutesParking;
    },
  },

  created() {
    window.addEventListener("resize", this.onResize);
    this.emitGeom();
    this.minutesCongestion = this.searchParams.minutesCongestion;
    this.minutesParking = this.searchParams.minutesParking;
  },
});
</script>

<style scoped>
.route ~ .route {
  margin-top: 2em;
}
</style>

