/*
 * JM 3.0 API - Mobilitydata Service
 * This API was created to be used internaly by EcoLibro as part of JM 3.0, a project aiming to improve data availability for intenally produced mobility analyses. Possible uses include a partly automated data transfer to QGIS for analytical purposes and a fully automated data transfer to a standalone application for future clients. The JM 3.0 API offers the following functions:
 *
 * OpenAPI spec version: 0.0.2
 * Contact: carsten.stahl@ecolibro.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The Geometry model module.
 * @module model/Geometry
 * @version 0.0.2
 */
export class Geometry {
  /**
   * Constructs a new <code>Geometry</code>.
   * GeoJSon geometry
   * @alias module:model/Geometry
   * @class
   * @param type {module:model/Geometry.TypeEnum} the geometry type
   */
  constructor(type) {
    this.type = type;
  }

  /**
   * Constructs a <code>Geometry</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Geometry} obj Optional instance to populate.
   * @return {module:model/Geometry} The populated <code>Geometry</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Geometry();
      if (data.hasOwnProperty('type'))
        obj.type = ApiClient.convertToType(data['type'], 'String');
    }
    return obj;
  }
}

/**
 * Allowed values for the <code>type</code> property.
 * @enum {String}
 * @readonly
 */
Geometry.TypeEnum = {
  /**
   * value: "Point"
   * @const
   */
  point: "Point",

  /**
   * value: "LineString"
   * @const
   */
  lineString: "LineString",

  /**
   * value: "Polygon"
   * @const
   */
  polygon: "Polygon"
};
/**
 * the geometry type
 * @member {module:model/Geometry.TypeEnum} type
 */
Geometry.prototype.type = undefined;

