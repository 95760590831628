/*
 * JM 3.0 API - Mobilitydata Service
 * This API was created to be used internaly by EcoLibro as part of JM 3.0, a project aiming to improve data availability for intenally produced mobility analyses. Possible uses include a partly automated data transfer to QGIS for analytical purposes and a fully automated data transfer to a standalone application for future clients. The JM 3.0 API offers the following functions:
 *
 * OpenAPI spec version: 0.0.2
 * Contact: carsten.stahl@ecolibro.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {Geometry} from './Geometry';
import {Point2D} from './Point2D';

/**
 * The Polygon model module.
 * @module model/Polygon
 * @version 0.0.2
 */
export class Polygon extends Geometry {
  /**
   * Constructs a new <code>Polygon</code>.
   * GeoJSon geometry
   * @alias module:model/Polygon
   * @class
   * @extends module:model/Geometry
   * @param type {} the geometry type
   */
  constructor(type) {
    super(type);
  }

  /**
   * Constructs a <code>Polygon</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Polygon} obj Optional instance to populate.
   * @return {module:model/Polygon} The populated <code>Polygon</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Polygon();
      Geometry.constructFromObject(data, obj);
      if (data.hasOwnProperty('coordinates'))
        obj.coordinates = ApiClient.convertToType(data['coordinates'], [[Point2D]]);
    }
    return obj;
  }
}

/**
 * @member {Array.<Array.<module:model/Point2D>>} coordinates
 */
Polygon.prototype.coordinates = undefined;

