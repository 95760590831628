<template>
  <div class="flex-box-center-items-vertical">
    <div class="flex-box-right-aligned-horizontal" style="align-items: flex-end">
      <div style="margin-right: 5px">Bitte geben Sie ihre Postleitzahl an</div>
      <v-tooltip top>
        <template v-slot:activator="{on, attrs}"
          ><v-icon
            :color="$vuetify.theme.themes.light.secondary"
            size="dense"
            dark
            v-bind="attrs"
            v-on="on"
            >mdi-help-circle</v-icon
          ></template
        >
        <span>
          Die Daten werden anonym gespeichert. Es wird nur die PLZ übermittelt,
          nicht die vollständige Adresse. Es ist anhand der Angaben kein
          Rückschluss auf Sie als Person möglich.
        </span>
      </v-tooltip>
      <input
        :value="pollPlz()"
        @change="pollPlzChanged"
        :style="colorOfInputElementsStyle"
        style="
          margin-left: 10px;
          margin-right: 10px;
          padding-left: 5px;
          width: 100px;
        "
      />
      <v-btn :disabled="!isPollValid || isSubmitted" @click="submitPoll()"
        >absenden</v-btn
      >
    </div>
    <span v-if="!isPollPlzValid()" style="color: red; align-self: end;">Postleitzahl ungültig!</span>
    <span v-if="!isFrequenciesValid()" style="color: red; align-self: end;">Gesamthäufigkeit der Transportvarianten ungültig!</span>
    <span v-if="!isTransportsUsedValid()" style="color: red; align-self: end;">Bitte wählen Sie genau eine Option aus der obenstehenden Liste aus!</span>
    
    <span
      :style="{
        color:
          isSubmitted && submitText
            ? $vuetify.theme.themes.light.secondary
            : submitText
            ? 'red'
            : '',
      }"
      >{{ submitText }}</span
    >
  </div>
</template>
<script>
import {usePollStore} from "../../store/poll-store";
import {storeToRefs} from "pinia";
import Vue from "vue";
import {savePollToDB} from "../../api";
import * as utils from "../../utils";
import {Poll} from "../../jm-api-client/src";

export default Vue.extend({
  name: "PollFooter",
  components: {},
  props: {
    routes: {type: Array, default: () => []},
  },

  data: () => ({
    isSubmitted: false,
    submitText: "",
    pollValid: false
  }),

  mounted() {},

  methods: {
    pollPlz() {
      const store = usePollStore();
      return storeToRefs(store).pollPlz.value;
    },
    isPollPlzValid() {
      return usePollStore().isPollPlzValid();
    },
    isFrequenciesValid() {
      return usePollStore().isPollFrequenciesValid();
    },
    isTransportsUsedValid() {
      return usePollStore().isTransportsUsedValid();
    },
    pollPlzChanged(event) {
      const plzStr = event.target.value;
      usePollStore().setPlz(plzStr);
      // this.updatePollPlz(plzStr);
    },
    async submitPoll() {
      let routesPoll = this.routes.map((route, i) => ({
        ...route,
        frequency: usePollStore().pollFrequency(i),
        nPassengers: usePollStore().numberPassengersOfTransport(i),
        transportUsed: usePollStore().isTransportUsed(i),
        nStars: usePollStore().getNumberStars(i)
      }));
      const poll = {
        pollType: storeToRefs(usePollStore()).pollType.value,
        plz: storeToRefs(usePollStore()).pollPlz.value,
        pollDate: storeToRefs(usePollStore()).pollDate.value,
        clientName: storeToRefs(usePollStore()).clientName.value,
        employeeId: Math.abs(Math.random() * 100000),
        monthsInOrg: Number(
          storeToRefs(usePollStore()).pollMonthsInOrganization.value
        ),
        monthsInOrgMax: Number(
          storeToRefs(usePollStore()).pollMonthsInOrganizationMax.value
        ),
        workdaysPerWeek: Number(
          storeToRefs(usePollStore()).pollWorkingDays.value
        ),
        workdaysPerWeekMax: Number(
          storeToRefs(usePollStore()).pollWorkingDaysMax.value
        ),
        homeofficeDaysPerWeek: Number(
          storeToRefs(usePollStore()).pollHomeofficeDays.value
        ),
        homeofficeDaysPerWeekMax: Number(
          storeToRefs(usePollStore()).pollHomeofficeDaysMax.value
        ),
        routes: routesPoll,
      };

      let poll_obj = Poll.constructFromObject(poll);
      let res;
      try {      
        res = await savePollToDB(poll_obj);
      } catch {
          this.submitText = "Leider ist ein Fehler bei der Übertragung aufgetreten.\n Bitte versuch es später wieder!";

      }
      if (res.status) {
        this.submitText =
          "Vielen Dank für deine Teilnahme! Die Daten wurden erfolgreich gespeichert!";
        this.isSubmitted = true;
      } else {
        this.submitText =
          "Leider ist ein Fehler bei der Übertragung aufgetreten.\n Bitte versuch es später wieder!";
      }
      usePollStore().resetSuveyInput();

      // console.log("mySavedPolls=", res);
    },
    // connectToApi(poll) {
    //   var defaultClient = ApiClient.instance;

    //   // Configure API key authorization: apiKey
    //   var apiKey = defaultClient.authentications["apiKey"];
    //   apiKey.apiKey = "abc12341";
    //   // Uncomment the following line to set a prefix for the API key, e.g. "Token" (defaults to null)
    //   //apiKey.apiKeyPrefix['apiKey'] = "Token"
    //   const pollApi = new PollApi(defaultClient);
    //   const aApi = new AdressesApi(defaultClient);
    //   aApi.postAdress({street: "test-street"});

    //   // pollApi
    //   //   .writePoll({body: {}})
    //   //   .then((res) => console.log("Response=", res))
    //   //   .catch((err) => console.log("Error: ", err));

    //   // var callback = function(error, data, response) {
    //   //   if (error) {
    //   //     console.error(error);
    //   //   } else {
    //   //     console.log('API called successfully. Returned data: ' + data);
    //   //   }
    //   // };
    //   // api.deleteAdress(adId, callback);
    // },
  },

  computed: {
    isPollValid() {
      return usePollStore().isPollValid()//isPollPlzValid();
    },
    colorOfInputElementsStyle() {
      return utils.colorOfInputElementsStyle.bind(this)();
    },
  },

  watch: {},
});
</script>

<style scoped>
.full-width {
  width: 100%;
}
.sort-btns >>> button:not(.v-btn--active),
.sort-btns >>> i,
button {
  color: #333 !important;
}
.background-white {
  background-color: white;
}
.padding-10px {
  padding: 10px;
}
.border-radius-10px {
  border-radius: 10px;
}
.flex-box-centered-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}
.font-size-22px {
  font-size: 22px;
}
.margin-left-15px {
  margin-left: 15px;
}
</style>
