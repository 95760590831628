import { render, staticRenderFns } from "./Route.vue?vue&type=template&id=ac48cee8&scoped=true&"
import script from "./Route.vue?vue&type=script&lang=js&"
export * from "./Route.vue?vue&type=script&lang=js&"
import style0 from "./Route.vue?vue&type=style&index=0&id=ac48cee8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac48cee8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VCol,VList,VListItem,VListItemGroup,VRow,VTab,VTabItem,VTabs,VTabsItems,VTimeline})
