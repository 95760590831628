<template>
  <v-dialog
    v-model="show"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="900px"
    class="z-index-1000"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot v-bind:on="on" v-bind:attrs="attrs" />
    </template>

    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title>Parameter — {{ transport.ui.name }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon @click="show = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>


      <v-card-text>
      <v-container fluid class="my-3">
        <v-row>
          <v-col class="text-body-1">
            Die untenstehenden Parameter liegen den berechneten Metriken zu Grunde. Eine detailierte tabellarische Übersicht über die Parameterwerte der einzelnen Transportmittel finden Sie
             <a :href="paramsOverviewLink" target="_blank">hier</a>.<br>
            <template v-if="transport.type === 'public_transit'">
              Ticketpreise verschiedener Tarifzonen sind hinterlegt, eine Abfrage des aktuellen Sparpreises ist nicht immer möglich.
            </template>
          </v-col>
        </v-row>

        <v-row v-for="(t, i) in transports" :key="i">
          <h1
            v-text="t.type === 'public_transit' ? 'ÖPV' : t.ui.name"
            class="col-12 text-center font-weight-light mt-8"
          />
          <v-col cols="12" md="6">
            <h2 class="font-weight-light">Kosten</h2>
            <v-simple-table class="mt-2">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Fixkosten</td>
                    <td>{{(cost(t).centsFixed || 0) / 100}} € / Nutzungsjahr</td>
                  </tr>
                  <tr>
                    <td>Streckenkosten</td>
                    <td>{{(cost(t).centsPerKm || 0) / 100}} € / km</td>
                  </tr>
                  <tr>
                    <td>Zeitkosten</td>
                    <td>
                      {{(cost(t).centsPerHour || 0) / 100}} € / Stunde
                      oder
                      {{(cost(t).centsPerFullDay || 0) / 100 }} € / Tag
                      {{ cost(t).roundUpTime ? '(aufgerundet)' : '' }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

          <v-col cols="12" md="6">
            <h2 class="font-weight-light">Zeit</h2>
            <v-simple-table class="mt-2">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Infahrtsetzung</td>
                    <td>{{time(t).minutesSetup}} Minuten</td>
                  </tr>
                  <tr>
                    <td>Abstellen</td>
                    <td>{{time(t).minutesTeardown}} Minuten</td>
                  </tr>
                  <tr>
                    <td>Geschwindigkeitsfaktor</td>
                    <td>{{(time(t).speedFactor || 1)}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

          <v-col cols="12" md="6">
            <h2 class="font-weight-light">CO₂</h2>
            <v-simple-table class="mt-2">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Fußabdruck Produktion</td>
                    <td>{{co2(t).gramsFixed}} kg / Nutzungsjahr</td>
                  </tr>
                  <tr>
                    <td>Fußabdruck Nutzung</td>
                    <td>{{co2(t).gramsPerKm}} g / km</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

          <v-col cols="12" md="6">
            <h2 class="font-weight-light">Bewegung</h2>
            <v-simple-table class="mt-2">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Schrittäquivalent</td>
                    <td>{{sport(t).stepsPerKm}} Schritte / km</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    name: 'TransportConfig',

    props: {
      transport: { type: Object, default: () => ({
        id: '',
        ui: { name: 'Foo', icon: 'bar' },
        metricParams: {
          cost: {},
          co2: {},
          time: {},
          sport: {},
        },
        firstMile: {},
        lastMile: {},
      })},
      loading: {type: Boolean, default: false}

    },

    data: () => ({
      show: false,
      paramsOverviewLink: "https://www.ecolibro.de/images/attach/CleverRoute_Produktblaetter/20220330CRParameterbersicht_1650616261.pdf"
    }),

    computed: {
      transports () {
        const { firstMile, lastMile } = this.transport
        const t = [this.transport]
        if (firstMile)
          t.push(firstMile)
        if (lastMile && firstMile.id !== lastMile.id)
          t.push(lastMile)
        return t
      },
    },

    methods: {
      cost (t) { return t.metricParams.cost },
      co2 (t) { return t.metricParams.co2 },
      time (t) { return t.metricParams.time },
      sport (t) { return t.metricParams.sport },
    },
  })
</script>

<style scoped>
td {
  min-width: 14em;
}
.z-index-1000 {
  z-index: 1000;
}
</style>
