import Keycloak from 'keycloak-js';

const options = {
  url: "http://localhost:8089",
  clientId: "cleverroute-app",
  realm: "cleverroute"
}

const keycloak = new Keycloak(options);
// let authenticated;
// let store = null;


/**
 * Initializes store with Keycloak user data
 *
 */
async function saveLoginKeycloak(storeInstance) {
  try {
    store = storeInstance
    store.initOauth(keycloak)

    // Show alert if user is not authenticated
    if (!authenticated) {
      return false;
    }
  } catch (error) {
    console.error("Keycloak init failed: ", error)
    return false;
  }
};


/**
 * Initializes Keycloak, then run callback. This will prompt you to login.
 *
 * @param onAuthenticatedCallback
 */
async function loginKeycloak() {
  try {
    // keycloak = new Keycloak(options);

    let authenticated = await keycloak.init({
      enableLogging: true,
      checkLoginIframe: false,
      onLoad: 'login-required',
    });
    console.log("is authenticated=", authenticated, keycloak)
    if (authenticated) {
      return true;

    } else {
      return false;
    }
    // onInitCallback()
  } catch (error) {
    console.error("Keycloak init failed: ", error);
    return false;
  }
};

/**
 * Logout user
 */
async function logoutKeycloak(url, storeInstance) {
  keycloak.logout({ redirectUri: url });

}

/**
 * Refreshes token
 */
async function refreshKeycloakToken() {
  try {
    await keycloak.updateToken(480);
    return keycloak;
  } catch (error) {
    console.error('Failed to refresh token');
    console.error(error);
  }
}

const KeycloakService = {
  loginKeycloak: loginKeycloak,
  logoutKeycloak: logoutKeycloak,
  refreshKeycloakToken: refreshKeycloakToken,
  keycloakObj: keycloak
};

export default KeycloakService;