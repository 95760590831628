/*
 * JM 3.0 API - Mobilitydata Service
 * This API was created to be used internaly by EcoLibro as part of JM 3.0, a project aiming to improve data availability for intenally produced mobility analyses. Possible uses include a partly automated data transfer to QGIS for analytical purposes and a fully automated data transfer to a standalone application for future clients. The JM 3.0 API offers the following functions:
 *
 * OpenAPI spec version: 0.0.2
 * Contact: carsten.stahl@ecolibro.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The MobScore model module.
 * @module model/MobScore
 * @version 0.0.2
 */
export class MobScore {
  /**
   * Constructs a new <code>MobScore</code>.
   * @alias module:model/MobScore
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>MobScore</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MobScore} obj Optional instance to populate.
   * @return {module:model/MobScore} The populated <code>MobScore</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MobScore();
      if (data.hasOwnProperty('weight_co2'))
        obj.weightCo2 = ApiClient.convertToType(data['weight_co2'], 'Number');
      if (data.hasOwnProperty('weight_sport'))
        obj.weightSport = ApiClient.convertToType(data['weight_sport'], 'Number');
      if (data.hasOwnProperty('weight_time'))
        obj.weightTime = ApiClient.convertToType(data['weight_time'], 'Number');
      if (data.hasOwnProperty('weight_cost'))
        obj.weightCost = ApiClient.convertToType(data['weight_cost'], 'Number');
      if (data.hasOwnProperty('budget_co2'))
        obj.budgetCo2 = ApiClient.convertToType(data['budget_co2'], 'Number');
      if (data.hasOwnProperty('budget_sport'))
        obj.budgetSport = ApiClient.convertToType(data['budget_sport'], 'Number');
      if (data.hasOwnProperty('budget_time'))
        obj.budgetTime = ApiClient.convertToType(data['budget_time'], 'Number');
      if (data.hasOwnProperty('budget_cost'))
        obj.budgetCost = ApiClient.convertToType(data['budget_cost'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Number} weightCo2
 */
MobScore.prototype.weightCo2 = undefined;

/**
 * @member {Number} weightSport
 */
MobScore.prototype.weightSport = undefined;

/**
 * @member {Number} weightTime
 */
MobScore.prototype.weightTime = undefined;

/**
 * @member {Number} weightCost
 */
MobScore.prototype.weightCost = undefined;

/**
 * @member {Number} budgetCo2
 */
MobScore.prototype.budgetCo2 = undefined;

/**
 * @member {Number} budgetSport
 */
MobScore.prototype.budgetSport = undefined;

/**
 * @member {Number} budgetTime
 */
MobScore.prototype.budgetTime = undefined;

/**
 * @member {Number} budgetCost
 */
MobScore.prototype.budgetCost = undefined;

