/*
 * JM 3.0 API - Mobilitydata Service
 * This API was created to be used internaly by EcoLibro as part of JM 3.0, a project aiming to improve data availability for intenally produced mobility analyses. Possible uses include a partly automated data transfer to QGIS for analytical purposes and a fully automated data transfer to a standalone application for future clients. The JM 3.0 API offers the following functions:
 *
 * OpenAPI spec version: 0.0.2
 * Contact: carsten.stahl@ecolibro.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {PathWithId} from './PathWithId';

/**
 * The PathsBody model module.
 * @module model/PathsBody
 * @version 0.0.2
 */
export class PathsBody extends PathWithId {
  /**
   * Constructs a new <code>PathsBody</code>.
   * @alias module:model/PathsBody
   * @class
   * @extends module:model/PathWithId
   */
  constructor() {
    super();
  }

  /**
   * Constructs a <code>PathsBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PathsBody} obj Optional instance to populate.
   * @return {module:model/PathsBody} The populated <code>PathsBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PathsBody();
      PathWithId.constructFromObject(data, obj);
      if (data.hasOwnProperty('success'))
        obj.success = ApiClient.convertToType(data['success'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {Boolean} success
 */
PathsBody.prototype.success = undefined;

