/*
 * JM 3.0 API - Mobilitydata Service
 * This API was created to be used internaly by EcoLibro as part of JM 3.0, a project aiming to improve data availability for intenally produced mobility analyses. Possible uses include a partly automated data transfer to QGIS for analytical purposes and a fully automated data transfer to a standalone application for future clients. The JM 3.0 API offers the following functions:
 *
 * OpenAPI spec version: 0.0.2
 * Contact: carsten.stahl@ecolibro.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {GeoPolygonGeo} from './GeoPolygonGeo';

/**
 * The GeoPolygon model module.
 * @module model/GeoPolygon
 * @version 0.0.2
 */
export class GeoPolygon {
  /**
   * Constructs a new <code>GeoPolygon</code>.
   * für die Erstellung und Darstellung von Polygonen
   * @alias module:model/GeoPolygon
   * @class
   * @param type {module:model/GeoPolygon.TypeEnum} 
   * @param geo {module:model/GeoPolygonGeo} 
   */
  constructor(type, geo) {
    this.type = type;
    this.geo = geo;
  }

  /**
   * Constructs a <code>GeoPolygon</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GeoPolygon} obj Optional instance to populate.
   * @return {module:model/GeoPolygon} The populated <code>GeoPolygon</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new GeoPolygon();
      if (data.hasOwnProperty('type'))
        obj.type = ApiClient.convertToType(data['type'], 'String');
      if (data.hasOwnProperty('geo'))
        obj.geo = GeoPolygonGeo.constructFromObject(data['geo']);
      if (data.hasOwnProperty('begin'))
        obj.begin = ApiClient.convertToType(data['begin'], 'Date');
      if (data.hasOwnProperty('end'))
        obj.end = ApiClient.convertToType(data['end'], 'Date');
      if (data.hasOwnProperty('rep'))
        obj.rep = ApiClient.convertToType(data['rep'], 'String');
      if (data.hasOwnProperty('poly_group'))
        obj.polyGroup = ApiClient.convertToType(data['poly_group'], 'Number');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
    }
    return obj;
  }
}

/**
 * Allowed values for the <code>type</code> property.
 * @enum {String}
 * @readonly
 */
GeoPolygon.TypeEnum = {
  /**
   * value: "traffic"
   * @const
   */
  traffic: "traffic",

  /**
   * value: "forecast"
   * @const
   */
  forecast: "forecast"
};
/**
 * @member {module:model/GeoPolygon.TypeEnum} type
 */
GeoPolygon.prototype.type = undefined;

/**
 * @member {module:model/GeoPolygonGeo} geo
 */
GeoPolygon.prototype.geo = undefined;

/**
 * Format: HH:MM:SS
 * @member {Date} begin
 */
GeoPolygon.prototype.begin = undefined;

/**
 * Format: HH:MM:SS
 * @member {Date} end
 */
GeoPolygon.prototype.end = undefined;

/**
 * Allowed values for the <code>rep</code> property.
 * @enum {String}
 * @readonly
 */
GeoPolygon.RepEnum = {
  /**
   * value: "daily"
   * @const
   */
  daily: "daily",

  /**
   * value: "weekly"
   * @const
   */
  weekly: "weekly",

  /**
   * value: "yearly"
   * @const
   */
  yearly: "yearly"
};
/**
 * @member {module:model/GeoPolygon.RepEnum} rep
 */
GeoPolygon.prototype.rep = undefined;

/**
 * @member {Number} polyGroup
 */
GeoPolygon.prototype.polyGroup = undefined;

/**
 * @member {String} name
 */
GeoPolygon.prototype.name = undefined;

