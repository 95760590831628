import { render, staticRenderFns } from "./TransportListing.vue?vue&type=template&id=28878474&scoped=true&"
import script from "./TransportListing.vue?vue&type=script&lang=js&"
export * from "./TransportListing.vue?vue&type=script&lang=js&"
import style0 from "./TransportListing.vue?vue&type=style&index=0&id=28878474&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28878474",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VBtnToggle,VCol,VContainer,VDataIterator,VIcon,VRow,VTooltip})
