<template>
  <div ref="vParallax" id="parallaxContainer">
    <!-- key on height to force rerender of parallax thingy -->
    <v-parallax
      src="hero.jpg"
      :class="{ blur }"
      :height="heightHeaderImg"
      style="
         {
          width: 100%;
        }
      "
      class="d-print-none"
    >
      <div class="hero-gradient fill-height">
        <div class="full-height flex-box-top-aligned-vertical">
          <v-container fill-height text-center>
            <v-row id="heading" align-content="center" justify="center" :style="{ 'font-size': fontSize}">
              <!-- using spans with custom flex instead of cols for higher
                line break flexibility. try <v-col tag="span">? -->

              <!-- using spans with custom flex instead of cols for higher
                line break flexibility. try <v-col tag="span">? -->

              <span
                class="pr-3" :style="$vuetify.breakpoint.smAndDown && 'flex: 0 0 100%'"
              >
              {{headerTitlePart(0)}}

              </span>

              <span
                class="pr-2"
                :class="typing ? '' : 'nocursor'"
                :style="$vuetify.breakpoint.smAndDown && 'flex: 0 0 100%'"
              >
                <span ref="typing" id="typed-heading" />
              </span>

              <span :style="$vuetify.breakpoint.md && 'flex: 0 0 100%'">
                {{headerTitlePart(1)}}
              </span>
            </v-row>
          </v-container>
          <div
            v-if="showHeaderCRlogo"
            class="
              margin-left-5px margin-bottom-5px
              position-bottom-absolute
              height-20-perc
            "
          >
            <img src="cleverroute-logo-white.png" class="full-height" />
          </div>
        </div>
      </div>
    </v-parallax>

    <v-card class="d-none d-print-flex justify-center">
      <v-card-title class="text-h3 font-weight-light">
        Ihre Anreise {{ destinationName }}
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import Typed from "typed.js";

export default Vue.extend({
  name: "Hero",

  props: {
    destinationName: String,
    headerTitleParts: {type: Array, default: ()=>(["",""])},
    height: Number,
    blur: Boolean,
    showHeaderCRlogo: Boolean,
  },

  data: () => ({
    typing: true,
    heightHeaderImg: 400
  }),
  methods: {
    async initTypedJs() {
      await this.$nextTick(); // wait until ref is ready
      const typed = new Typed(this.$refs.typing, {
       strings: [
         'schnellste', 'kostengünstigste', 'umweltfreundlichste', 'gesündeste', 'cleverste'
        ],
        typeSpeed: 45,
        backSpeed: 24,
        startDelay: 1100,
        backDelay: 1700,
        contentType: "text",
        onComplete: () => (this.typing = false),
      });
    },
    headerTitlePart(i) {
      if (this.headerTitleParts.length-1 >= i) {
        return this.headerTitleParts[i];
      } else {
        return "";
      }
    },
  },
  mounted() {
    const resizeObserver = new ResizeObserver(() => {
      // adapt resize of map after user clicks on "show available transports" panel
      if (this.$refs.vParallax) {
        this.heightHeaderImg = this.$refs.vParallax.scrollWidth * 0.2427;
      }
    });

    resizeObserver.observe(document.getElementById("parallaxContainer"));
  },
  computed: {
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '1em'
        case 'sm': return '1em'
        case 'md': return '2em'
        case 'lg': return '3em'
        case 'xl': return '3em'
      }
    }
  },
  watch: {
    height() {
      this.initTypedJs();
    },
  },
});
</script>

<style scoped>
.v-parallax.blur >>> .v-parallax__image {
  filter: brightness(0.95) grayscale(0.2) blur(2px);
}
.hero-gradient {
  background: linear-gradient(
    0deg,
    rgba(60, 60, 60, 0.4) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(130, 130, 130, 0.4) 100%
  );
}

#heading {
  color: white;
  font-weight: 500;
  /* font-size: 3em !important; */
  text-shadow: black 0px 0px 30px, black 0px 0px 8px;
}
#typed-heading {
  font-weight: 400;
}
#heading >>> .typed-cursor {
  color: #ddd;
}
#heading .nocursor >>> .typed-cursor {
  display: none;
}
</style>
