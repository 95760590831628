<template>
  <v-timeline-item
    :icon="legIcon"
    :color="$vuetify.theme.isDark ? 'secondary' : 'primary'"
    fill-dot
    class="legitem"
  >
    <v-list-item>
      <v-row no-gutters class="full-max-width">
        <v-col :cols="titleCols">
          <v-list-item-content>
            <v-list-item-title class="legdetails"
              >{{ title }}
              <span style="font-size: small" v-if="routeDirection"
                ><v-icon small>arrow_forward</v-icon>{{ routeDirection }}</span
              ></v-list-item-title
            >
            <v-list-item-subtitle class="legdetails"
              ><span style="white-space: pre-line">{{ getSubtitle() }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-col>
        <v-col
          v-if="!isSmallScreenWidth"
          :cols="compoundCols"
          class="d-flex justify-center align-center"
        >
          <span class="text-center">
            <img
              v-if="iconPath !== '' && iconPath && iconExists()"
              :style="getIconSizeStyle()"
              :src="`${iconPath}`"
            />
            <span v-else-if="compoundName !== '' && compoundName">{{
              compoundName
            }}</span>
          </span>
        </v-col>
        <v-col
          :cols="trackCols"
          v-if="showTrackRow"
          class="d-flex justify-center align-center"
        >
          <span class="text-center flex-box-top-aligned-vertical full-height">
            <v-list-item-subtitle
              v-once
              class="legdetails flex-box-bottom-aligned-vertical"
              >{{ departurePlatform }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              v-if="leg.mode == 'car'"
              v-once
              class="legdetails"
            ></v-list-item-subtitle>
            <v-list-item-subtitle v-once class="legdetails">{{
              arrivalPlatform
            }}</v-list-item-subtitle>
          </span>
        </v-col>
        <v-col
          :cols="priceCols"
          v-if="!isSmallScreenWidth"
          class="d-flex justify-center align-center"
        >
          <span class="text-center full-height flex-box-top-aligned-vertical">
            <v-list-item-subtitle
              v-once
              class="legdetails flex-box-bottom-aligned-vertical"
              ><div>
                {{ ticketPrice
                }}<span v-if="ticketPrice !== ''" style="font-size: 1.1em">
                  €</span
                >
                <v-tooltip top v-if="priceHint">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" x-small style="color: red">
                      *
                    </span>
                  </template>
                  <span style="white-space: pre;" >{{ priceHint }}</span>
                </v-tooltip>
              </div>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-once class="legdetails">
              {{ co2 }}
            </v-list-item-subtitle>
          </span>
        </v-col>

        <v-col :cols="arrivalCols" class="d-flex justify-center align-center">
          <span class="text-center stats">
            <v-list-item-subtitle v-once class="legdetails"
              >{{ departure }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-once class="legdetails">{{
              arrival
            }}</v-list-item-subtitle>
          </span>
        </v-col>
        <v-col :cols="durationCols" class="d-flex justify-center align-center">
          <span class="text-center">
            <v-list-item-subtitle v-once class="legdetails">
              <v-tooltip top v-if="leg.mode == 'car'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    x-small
                    class="material-symbols-outlined"
                  >
                    info
                  </v-icon>
                </template>
                <span
                  >inkl. Stauaufschlag von
                  {{ minutesCongestion ? minutesCongestion : 0 }} min und
                  Parkaufschlag von
                  {{ minutesParking ? minutesParking : 0 }} min <br />(kann
                  unter 'weitere Optionen' geändert werden)</span
                >
              </v-tooltip>
              {{ duration }}</v-list-item-subtitle
            >

            <v-list-item-subtitle
              v-once
              v-text="distance"
              class="legdetails"
              v-if="!isWaiting"
            />
       
          </span>
        </v-col>
      </v-row>
    </v-list-item>

    <Map
      v-if="printView && leg.geometry"
      height="240px"
      width="48em"
      :geojsonRoutes="geojsonRoutes"
      :gesture-handling="false"
      :zoom-control="false"
    />
  </v-timeline-item>
</template>

<script>
import Vue from "vue";
import Map from "./Map.vue";
import { routeModesOEPV } from "../ui-mappings";
import { formatTime, formatDuration } from "../utils";
import { availableCompoundsIcons } from "../models/constants-transports";

export default Vue.extend({
  name: "RouteLeg",

  components: { Map },

  props: {
    leg: { type: Object, default: () => ({}) }, // geojson feature
    minutesCongestion: { type: String, default: "0" },
    minutesParking: { type: String, default: "0" },
    printView: { type: Boolean, default: false },
    ticketPrice: { type: String, default: "" },
    iconPath: { type: String, default: "" },
    departurePlatform: { type: String, default: "" },
    arrivalPlatform: { type: String, default: "" },
    co2: { type: String, default: "" },
    isSmallScreenWidth: { type: Boolean, default: false },
    titleCols: { type: Number, default: 4 },
    trackCols: { type: Number, default: 1 },
    priceCols: { type: Number, default: 2 },
    compoundCols: { type: Number, default: 1 },
    arrivalCols: { type: Number, default: 2 },
    durationCols: { type: Number, default: 2 },
    showTrackRow: { type: Boolean, default: true },
    compoundName: { type: String, default: "" },
    priceHint: { type: String, default: "" },
  },

  data: () => ({
    title: "",
    subtitle: "",
    routeDirection: undefined,
    minIconSize: 30,
    maxIconSize: 30,
    innerWidth: window.innerWidth,
  }),
  methods: {
    /* checks if the compound icons exists already in the public-folder */
    iconExists() {
      if (!this.leg.verbundNameShort) {
        return true;
      }

      //check whether icon for the compound exists by looking up in a mapping
      let compoundIcon = availableCompoundsIcons.find(
        (icon) => this.leg.verbundNameShort === icon
      );
      return compoundIcon;
    },
    /* set min-height or min-width of image so that all icons have much the same dimension */
    getIconSizeStyle() {
      let img = new Image();
      img.src = this.iconPath;
      let myStyle = { "max-height": "40px", "max-width": "40px" };

      if (img.height > img.width) {
        myStyle = { ...myStyle, "min-height": this.minIconSize + "px" };
      } else {
        myStyle = { ...myStyle, "min-width": this.minIconSize + "px" };
      }
      return myStyle;
    },
    getSubtitle() {
      let subtitle = this.leg.details;
      if (!subtitle) {
        return "";
      }
      let firstPart = subtitle.slice(0, String(subtitle).length / 2);
      let secondPart = subtitle.slice(String(subtitle).length / 2);
      return firstPart + "" + secondPart;
    },
    onResize() {
      this.innerWidth = window.innerWidth;
    },
  },
  computed: {
    legIcon() {
      if (
        this.leg.transportType === "train" ||
        this.leg.transportType.includes("ride")
      ) {
        return routeModesOEPV[this.leg.mode].icon;
      } else if (this.leg.materialIcon) {
        return this.leg.materialIcon;
      }
      // return this.leg.materialIcon || routeModes2[this.leg.transportType].icon;5
      // ui:
      //   name: e-Scooter
      //   materialIcon: electric_scooter
    },
    arrival() {
      return formatTime(this.leg.arrival);
    },
    departure() {
      return formatTime(this.leg.departure);
    },
    duration() {
      return formatDuration(this.leg.durationM);
    },
    distance() {
      return `${Number(
        Math.round(10 * this.leg.distanceKm) / 10
      ).toLocaleString()} km`;
    },
    isWaiting() {
      return this.leg.mode === "waiting";
    },
    geojsonRoutes() {
      // returning just the leg geometry as a 1 element array
      return [this.leg.geometry];
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  created() {
    window.addEventListener("resize", this.onResize);
    const { description, details } = this.leg;
    this.routeDirection = this.leg.direction;
    if (this.leg.mode === "foot") {
      this.title = routeModesOEPV[this.leg.mode]?.name;
    } else if (
      this.leg.transportType === "train" ||
      this.leg.transportType.includes("ride")
    ) {
      // this.title = routeModesOEPV[this.leg.mode]?.name + ' ' + descriptionLabel;
      this.title = description ? description : "";
    } else if (this.leg.transportName) {
      this.title = this.leg.transportName;
    } else if (description) {
      this.title = description;
    } else {
      this.title = "";
    }
    // this.title = routeModes2[this.leg.transportType]?.name || description || "";
    // this.subtitle = details;
  },
});
</script>

<style scoped>
.legitem >>> .v-timeline-item__dot {
  margin-top: 5px;
}

.times {
  min-width: 7em;
}
.stats {
  min-width: 4.5em;
}

@media print {
  .legitem {
    page-break-inside: avoid;
  }
  .legitem ~ .legitem {
    margin-top: 2em;
  }
  .legdetails {
    font-size: larger;
  }
  .times {
    min-width: 10em;
  }
  .stats {
    min-width: 7em;
  }
}
</style>
