export const metricsDisplayParams = [
  { id: 'time', name: 'Zeit', icon: 'timer', },
  { id: 'cost', name: 'Kosten', icon: 'euro_symbol', },
  { id: 'co2', name: 'CO₂', icon: 'filter_drama', },
  { id: 'sport', name: 'Bewegung', icon: 'directions_run', },
]

export const routeModesOEPV = {
  waiting:  { icon: 'timer', name: 'Puffer' },
  foot:     { icon: 'directions_walk',    name: 'Zu Fuß', },
  bike: { icon: 'directions_bike', name: 'Fahrrad', },
  escooter: { icon: 'electric_scooter', name: 'e-Scooter', },
  car:      { icon: 'directions_car',     name: 'Auto', },
  ferry:    { icon: 'directions_boat',    name: 'Fähre', },
  bus:      { icon: 'directions_bus',     name: 'Bus', },
  national: { icon: 'directions_railway', name: 'IC', },
  regional: { icon: 'directions_transit', name: 'RE', },
  subway:   { icon: 'subway',             name: 'U', },
  taxi:     { icon: 'local_taxi',         name: 'Taxi', },
  tram:     { icon: 'tram',               name: 'Tram', },
  suburban: { icon: 'directions_transit', name: 'S', },
  nationalExpress: { icon: 'directions_railway', name: 'ICE' },
  regionalExp: { icon: 'directions_transit', name: 'RE' }
}

// export const routeModes2 = {
//   waiting: { icon: 'timer', name: 'Puffer' },
//   walk: { icon: 'directions_walk', name: 'Zu Fuß', },
//   bike: { icon: 'directions_bike', name: 'Fahrrad', },
//   escooter: { icon: 'electric_scooter', name: 'e-Scooter', },
//   car: { icon: 'directions_car', name: 'Auto', },
//   ferry: { icon: 'directions_boat', name: 'Fähre', },
//   bus: { icon: 'directions_bus', name: 'Bus', },
//   national: { icon: 'directions_railway', name: 'IC', },
//   regional: { icon: 'directions_transit', name: 'RE', },
//   subway: { icon: 'subway', name: 'U', },
//   taxi: { icon: 'local_taxi', name: 'Taxi', },
//   tram: { icon: 'tram', name: 'Tram', },
//   suburban: { icon: 'directions_transit', name: 'S', },
//   nationalExpress: { icon: 'directions_railway', name: 'ICE' },
//   regionalExp: { icon: 'directions_transit', name: 'RE' },
//   carsharing: { name: "Car-Sharing" },
//   bikesharing: { name: "Bike-Sharing" },
//   pendlerportal: { name: "Fahrgemeinschaft" },
//   eCar: { name: "E-Auto" }
// }

