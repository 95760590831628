var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"hovercard",class:_vm.expanded && 'expanded',attrs:{"outlined":""}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"rounded":"","height":"6","striped":"","value":_vm.percentageTransportsComputed}}):_vm._e(),_c('v-card-title',{staticClass:"py-3 py-md-0 pr-md-0",on:{"click":_vm.toggleExpand}},[_c('v-row',{staticClass:"align-center full-max-width",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center my-0 my-md-3",attrs:{"cols":_vm.printView ? undefined : 12,"md":"6","lg":"4","xl":"5"}},[_c('TransportSymbolsBox',{attrs:{"transport":_vm.t.transport,"boxAlignment":"horizontal","color":_vm.$vuetify.theme.isDark ? '' : 'primary',"isOverviewMode":true}}),(_vm.warningMessage)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"color":"red"},attrs:{"x-small":""}},'span',attrs,false),on),[_vm._v(" * ")])]}}],null,false,1496907666)},[_c('span',[_vm._v(_vm._s(_vm.warningMessage))])]):_vm._e(),(_vm.getProviderIconPath(_vm.t))?_c('img',{staticClass:"top-widget-logo",staticStyle:{"margin":"5px","width":"60px"},attrs:{"src":_vm.getProviderIconPath(_vm.t)}}):(_vm.getProviderName(_vm.t))?_c('p',{staticClass:"top-widget-logo",staticStyle:{"margin":"5px","width":"60px"}},[_vm._v(" "+_vm._s(_vm.getProviderName(_vm.t))+" ")]):_vm._e()],1),_c('v-col',{staticClass:"mt-2 mb-0 my-md-3"},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.metrics),function(m){return _c('v-col',{key:m.name,staticClass:"pa-1 text-md-center d-flex align-center justify-center font-weight-light text-break",attrs:{"cols":"6","lg":"3"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.formatMetricIcon(m, _vm.t.transport)))]),_c('span',{staticClass:"pl-1"},[_vm._v(" "+_vm._s(_vm.formatMetric(m.id, _vm.t))+" ")]),(_vm.priceHint && m.id == 'cost')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"color":"red"},attrs:{"x-small":""}},'span',attrs,false),on),[_vm._v(" * ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.priceHint))])]):_vm._e()],1)}),1)],1)],1)],1),_c('v-expand-transition',[(_vm.expanded)?_c('div',{staticClass:"details"},[_c('v-divider'),_c('v-container',{staticClass:"my-3"},[_c('v-row',{attrs:{"justify":"end"}},[(!!_vm.description)?_c('v-col',{staticClass:"pt-0"},[_c('v-subheader',{staticClass:"mt-0 text-uppercase"},[_vm._v("Beschreibung")]),_vm._m(0)],1):_vm._e(),_c('v-col',{staticClass:"d-print-none d-flex justify-end",attrs:{"cols":"12","sm":"auto"}},[_c('div',{staticClass:"d-flex flex-column flex-xl-row full-width"},[_vm._l((_vm.bookingLinks),function(ref){
var name = ref.name;
var url = ref.url;
return _c('v-btn',{key:name + url,staticClass:"mr-lg-6 mb-3",attrs:{"href":url,"target":"_blank","block":_vm.$vuetify.breakpoint.xs,"large":"","color":"primary"}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("payments")]),_vm._v(" "+_vm._s(name)+" ")],1)}),_c('TransportConfig',{attrs:{"transport":_vm.t.transport},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-lg-6 mb-3",attrs:{"large":_vm.$vuetify.breakpoint.smAndUp,"block":_vm.$vuetify.breakpoint.xs}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v("info")]),_vm._v(" Parameter ")],1)]}}],null,false,1436264197)})],2)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('Route',{attrs:{"searchParams":_vm.searchParams,"routes":_vm.routes,"printView":_vm.printView,"directionTab":_vm.direction,"updateDirection":_vm.updateDirection},on:{"geometry-changed":_vm.updateMap}})],1),(!_vm.printView)?_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('Map',{attrs:{"height":"400px","geojsonRoutes":_vm.geojsonRoutes,"gesture-handling":_vm.$vuetify.breakpoint.mdAndDown,"originLonLatStr":_vm.originLonLat,"destinationLonLatStr":_vm.destinationLonLat,"disableAutoFocus":true}})],1):_vm._e()],1)],1)],1):_vm._e()]),_c('v-divider',{staticClass:"d-print-none"}),_c('v-card-actions',{staticClass:"justify-center d-print-none",attrs:{"role":"button"},on:{"click":_vm.toggleExpand}},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.expanded ? 'expand_less' : 'expand_more')}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-4 text-body-1 text-justify",domProps:{"innerHTML":_vm._s(_vm.description)}})}]

export { render, staticRenderFns }