<template>
  <div
    :style="{
      minHeight: `${height}px`,
      height: expanded ? 'auto' : `${height}px`,
    }"
  >
    <v-container fill-height class="my-3">
      <v-row class="text-body-1 text-lg-h6 font-weight-medium text-center">
        <v-col cols="12" sm="6" class="d-flex">
          <v-card
            outlined
            class="pa-4 flex-grow-1 d-flex align-center justify-center"
          >
            Erhalten Sie einen Überblick über alle verfügbaren Verkehrsmittel
            zur Anreise {{ placename }}
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" class="d-flex">
          <v-card
            outlined
            class="pa-4 flex-grow-1 d-flex align-center justify-center"
          >
            Vergleichen Sie Dauer, Kosten & CO₂-Fußabdruck &amp; körperliche
            Bewegung
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center" class="text-center">
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          class="mt-12"
          v-for="(t, i) in shownTransports"
          :key="i"
        >
          <div class="pa-3">
            <v-avatar
              class="mb-3"
              color="secondary !important"
              size="140px"
              rounded="circle"
              style="margin-top: -60px"
            >
              <v-avatar
                class="mb-3 avatar2"
                color="white !important"
                size="134px"
                rounded="circle"
                style="margin-top: 12px"
              >
                <v-icon color="primary" size="100px">{{ t.ui.icon }}</v-icon>
              </v-avatar>
            </v-avatar>
            <h2>{{ t.ui.name }}</h2>
          </div>
        </v-col>
      </v-row>
      <span
        v-show="!expanded"
        class="flex-grow-1 text-center text-button"
        role="button"
        @click.stop="expanded = !expanded"
      >
        <v-icon v-text="expanded ? 'expand_less' : 'expand_more'" />
        Alle Verkehrsmittel anzeigen
      </span>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "LandingInfo",

  props: {
    height: { type: Number },
    placename: { type: String, default: "" },
    transports: { type: Array, default: () => [] },
  },

  data: () => ({
    expanded: false,
  }),

  computed: {
    shownTransports() {
      return this.expanded ? this.transports : this.transports.slice(-3);
    },
  },
});
</script>
