<template>
  <div
    :style="{'background-color': 'white', 'padding': '15px', 'border': '2px solid '+ $vuetify.theme.themes.light.secondary}"
    elevation="2"
  >
    <h3>{{ title }}</h3>

    <!-- <v-card-text> -->
    <!-- <v-row>
            <h3>Umfrage</h3>
        </v-row> -->
    <v-row>
      <v-col cols="12"> </v-col>
    </v-row>
    <div>
      <v-row >
        <v-col cols="12">
            <span v-for="(myText, i) in introductionSteps" :key="i">
          {{ myText }} <br>
        </span>
          <!-- -->
        </v-col>
      </v-row>
    </div>
    <v-divider v-if="(pollMonthsInOrganization || pollWorkingDays || pollHomeofficeDays)" style="margin-top: 10px; margin-bottom: 10px" orientation="horizontal"></v-divider>
    <v-row v-if="(pollMonthsInOrganization || pollWorkingDays || pollHomeofficeDays)">
      <v-col v-if="pollMonthsInOrganization !== undefined" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
        <div class="flex-box-center-items-vertical" style="align-items: center">
        <div class="flex-box-center-items-horizontal">
          <div style="margin: 5px">Monate in Organisation 2023</div>
          <v-tooltip top>
            <template v-slot:activator="{on, attrs}"
              ><v-icon :color="$vuetify.theme.themes.light.secondary" size="dense" dark v-bind="attrs" v-on="on"
                >mdi-help-circle</v-icon
              ></template
            >
            <span
              >Pass hier falls notwendig die Zahl der Monate an, die du
              im aktuellen Jahr in der Organisation tätig bist.
            </span>
          </v-tooltip>
          <input
            :style="colorOfInputElementsStyle"
            style="padding-left: 5px; width: 40px"
            @change="
              pollOverviewDataChanged(
                PollData.PollMonthsInOrganization,
                $event.target.value
              )
            "
            :value="pollMonthsInOrganization"
          />
          <span class="flex-box-center-items-vertical">
            / 12</span
          >
        </div>
        <span v-if="!isPollMonthsInOrganizationValid()" style="color: red">Eintrag ungültig!</span>
        </div>
      </v-col>
      <v-col v-if="pollWorkingDays !== undefined" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
        <div class="flex-box-center-items-vertical" style="align-items: center">
        <div class="flex-box-center-items-horizontal" style="align-items: center">
          <div style="margin: 5px">Arbeitstage (Woche)</div>
          <!-- <v-tooltip top>
            <template v-slot:activator="{on, attrs}"
              ><v-icon color="black" size="dense" dark v-bind="attrs" v-on="on"
                >mdi-help-circle</v-icon
              ></template
            >
            <span
              > Passen Sie hier falls notwendig die Zahl der Monate an, die Sie im aktuellen Jahr in der Organisation tätig sind. 
            </span>
          </v-tooltip> -->
          <input
            style="padding-left: 5px; width: 40px"
            :style="colorOfInputElementsStyle"
            @change="
              pollOverviewDataChanged(PollData.PollWorkingDays, $event.target.value)
            "
            :value="pollWorkingDays"
          />
        </div>
        <span v-if="!isPollWorkingDaysValid()" style="color: red">Eintrag ungültig!</span>

        </div>
      </v-col>
      <v-col v-if="pollHomeofficeDays !== undefined" :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
        <div class="flex-box-center-items-vertical" style="align-items: center">
        <div class="flex-box-center-items-horizontal">
          <div style="margin: 5px">Homeoffice-Tage (Woche)</div>
          <v-tooltip top>
            <template v-slot:activator="{on, attrs}"
              ><v-icon :color="$vuetify.theme.themes.light.secondary" size="dense" dark v-bind="attrs" v-on="on"
                >mdi-help-circle</v-icon
              ></template
            >
            <span
              >Pass hier falls notwendig die Zahl deiner Homeoffice-Tage in einer durchschnittlichen Arbeitswoche an. 
            </span>
          </v-tooltip>
          <input
            style="padding-left: 5px; width: 40px"
            :style="colorOfInputElementsStyle"
            @change="
              pollOverviewDataChanged(PollData.PollHomeofficeDays, $event.target.value)
            "
            :value="pollHomeofficeDays"
          />
        </div>
        <span v-if="!isPollHomeofficeDaysValid()" style="color: red">Eintrag ungültig!</span>

        </div>
      </v-col>
    </v-row>
  </div>
  <!-- </v-card-text> -->
</template>

<script>
import Vue from "vue";
import Transport from "../Transport.vue";
import { usePollStore } from '../../store/poll-store';
import { storeToRefs } from "pinia";
import * as utils from "../../utils";

export default Vue.extend({
  name: "TransportListing",

  components: {
    Transport,
  },

  props: {
    config: {type: Object, default: undefined},
    routes: {type: Array, default: () => []},
  
  },

  data: () => ({
    PollData: {
      PollWorkingDays: 0,
      PollMonthsInOrganization: 1,
      PollHomeofficeDays: 2
    }
  }),

  mounted() {},

  methods: {
   
    isPollMonthsInOrganizationValid() {
      return usePollStore().isPollMonthsInOrganizationValid() || false;
    },
    isPollWorkingDaysValid() {
      return usePollStore().isPollWorkingDaysValid() || false;
    },
    isPollHomeofficeDaysValid() {
      return usePollStore().isPollHomeofficeDaysValid() || false;
    },
    pollOverviewDataChanged(key, val) {
      if (val) {
        switch (key) {
          case this.PollData.PollWorkingDays: usePollStore().setPollWorkingDays(val); break;
          case this.PollData.PollMonthsInOrganization: usePollStore().setPollMonthsInOrganization(val); break;
          case this.PollData.PollHomeofficeDays: usePollStore().setPollHomeofficeDays(val); break;
        }
        // let generalSettings = this.pollOverviewData.generalSettings ? {...this.pollOverviewData.generalSettings} : {};
        // this.updatePollOverviewData({
        //   ...this.pollOverviewData,
        //   generalSettings:{
        //     ...generalSettings,
        //   [key]: Number(val)
        //   }
            
        // });
      }
    },
  },

  computed: {
    usePoll() {
      return usePollStore().usePoll.value || false;
    },
    title() {
      return storeToRefs(usePollStore()).title.value || "";
    },
    introductionSteps() {
      return storeToRefs(usePollStore()).introductionSteps.value || [];
    },
    colorOfInputElementsStyle() {
      return utils.colorOfInputElementsStyle.bind(this)();
    },
    pollHomeofficeDays() {
      return storeToRefs(usePollStore()).pollHomeofficeDays.value; 
    },
    pollWorkingDays() {
      return storeToRefs(usePollStore()).pollWorkingDays.value;
    },
    pollMonthsInOrganization() {
      return storeToRefs(usePollStore()).pollMonthsInOrganization.value;
    },
  },

  watch: {},
});
</script>

<style scoped>
.full-width {
  width: 100%;
}
.sort-btns >>> button:not(.v-btn--active),
.sort-btns >>> i,
button {
  color: #333 !important;
}
.background-white {
  background-color: white;
}
.padding-10px {
  padding: 10px;
}
.border-radius-10px {
  border-radius: 10px;
}
.flex-box-centered-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}
.font-size-22px {
  font-size: 22px;
}
.margin-left-15px {
  margin-left: 15px;
}
</style>
