/*
 * JM 3.0 API - Mobilitydata Service
 * This API was created to be used internaly by EcoLibro as part of JM 3.0, a project aiming to improve data availability for intenally produced mobility analyses. Possible uses include a partly automated data transfer to QGIS for analytical purposes and a fully automated data transfer to a standalone application for future clients. The JM 3.0 API offers the following functions:
 *
 * OpenAPI spec version: 0.0.2
 * Contact: carsten.stahl@ecolibro.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The Metrics model module.
 * @module model/Metrics
 * @version 0.0.2
 */
export class Metrics {
  /**
   * Constructs a new <code>Metrics</code>.
   * Metriken welche abgegeben werden
   * @alias module:model/Metrics
   * @class
   * @param co2 {Number} 
   * @param cost {Number} 
   * @param sport {Number} 
   * @param timeInMins {Number} 
   */
  constructor(co2, cost, sport, timeInMins) {
    this.co2 = co2;
    this.cost = cost;
    this.sport = sport;
    this.timeInMins = timeInMins;
  }

  /**
   * Constructs a <code>Metrics</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Metrics} obj Optional instance to populate.
   * @return {module:model/Metrics} The populated <code>Metrics</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Metrics();
      if (data.hasOwnProperty('co2'))
        obj.co2 = ApiClient.convertToType(data['co2'], 'Number');
      if (data.hasOwnProperty('cost'))
        obj.cost = ApiClient.convertToType(data['cost'], 'Number');
      if (data.hasOwnProperty('sport'))
        obj.sport = ApiClient.convertToType(data['sport'], 'Number');
      if (data.hasOwnProperty('time_in_mins'))
        obj.timeInMins = ApiClient.convertToType(data['time_in_mins'], 'Number');
      if (data.hasOwnProperty('distance'))
        obj.distance = ApiClient.convertToType(data['distance'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Number} co2
 */
Metrics.prototype.co2 = undefined;

/**
 * @member {Number} cost
 */
Metrics.prototype.cost = undefined;

/**
 * @member {Number} sport
 */
Metrics.prototype.sport = undefined;

/**
 * @member {Number} timeInMins
 */
Metrics.prototype.timeInMins = undefined;

/**
 * @member {Number} distance
 */
Metrics.prototype.distance = undefined;

