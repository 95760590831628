import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import { Map } from 'leaflet'
import { GestureHandling } from 'leaflet-gesture-handling'
import { createPinia, PiniaVuePlugin } from 'pinia'
import smoothscroll from 'smoothscroll-polyfill'
// import VueMatomo from 'vue-matomo'
import "./styles/global.css"

Map.addInitHook('addHandler', 'gestureHandling', GestureHandling)

Vue.use(PiniaVuePlugin);
const pinia = createPinia()

Vue.config.productionTip = false
smoothscroll.polyfill();


new Vue({
  vuetify,
  pinia,
  render: h => h(App)
}).$mount('#app')

