export const TransportModalTypes = {
    MULTI: "MULTI",
    INTERMODAL: "INTERMODAL"
}

export const TransportTypesMaterialsDict = {
    rideAndBikesharing: ['directions_walk','train', 'bike'],
    rideAndBike: 'rideAndBike',
    carsharing: 'carsharing',
    rideAndCarsharing: 'rideAndCarsharing',
    rideAndCar: 'rideAndCar',
    bikesharing: 'bike',
    bike: 'directions_bike',
    car: 'car_rental',
    eCar: 'eCar',
    eBike: 'electric_bike',
    rideAndTaxi: 'rideAndTaxi',
    walk: 'walk',
    train: 'train',
    trainRegio: 'trainRegio',
    taxi: 'taxi'
}

export const OEPVmodes = ['bus',
    'tram',
    'suburban',
    'subway',
    'ferry',
    'express',
    'nationalExpress',
    'regional',
    'national',
    'regionalExpress',    
    'foot']
